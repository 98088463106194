import styles from './cssForLogon/Logon.module.css'
import LogoGoogle from './cssForLogon/google_logo.png'
import Logo_inicio from './../img/logo_vertical_dark.png'
import OpenedPlace from './../img/oppened.png'
import { Link } from 'react-router-dom'
import { auth } from './../firebase';
import firebase from "firebase";

const Register =({loginGoogle})=>{
    const db = firebase.firestore()

    function sendPasswordReset(e) {
        e.preventDefault()

        let email1 = document.getElementById('email1').value;
        let email2 = document.getElementById('email2').value;
        let senha = document.getElementById('password').value;

        {email1 === email2 ?
        (
        auth.createUserWithEmailAndPassword(email1,senha)
        .then(()=>{
            db.collection('usuarios').add({
              email: email1,
              timestamp: firebase.firestore.FieldValue.serverTimestamp()
            }).then((authUser)=>{
                authUser.user.updateProfile({
                    email:email1
                }).catch((error)=>{
                    alert(error.message);
                })
            })
          })
        
        )
        :
        (alert('Porfavor, digite o e-mail corretamente'))}
      }

    return(
        <div className={styles.container_logon}>
            <div className={styles.container_form_panda}>
                <div className={styles.logo_logon}>
                    <img alt="" src={Logo_inicio} />
                </div>
                <div className={styles.opened_place}>
                    <img alt="" src={OpenedPlace} />
                </div>
                <div className={styles.form_panda}>
                    <form onSubmit={sendPasswordReset}>
                        <input
                            id="email1"
                            name="email1"
                            placeholder="Digite seu melhor e-mail..."
                            type="email"
                        />
                        <input
                            id="email2"
                            name="email2"
                            placeholder="Repita seu melhor e-mail..."
                            type="email"
                        />
                        <input
                            id="password"
                            name="password"
                            placeholder="Crie uma senha..."
                            type="password"
                        />
                        <input type="submit" name="action" value="Cadastrar!"/>
                    </form>
                </div>
                <div className={styles.no_login}>
                    <Link to="/"><p>Voltar à tela de login</p></Link>
                    <Link to="/recover"><p>Esqueci minha senha...</p></Link>
                </div>
                <div className={styles.login_google}>
                    <button onClick={loginGoogle}><img alt="" src={LogoGoogle} /><span>Entrar com Google</span></button>
                </div>
            </div>
        </div>
        )
}

export default Register