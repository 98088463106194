import firebase from 'firebase';

const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyDrfGsnVrah4ObHrLIiV2RnuFrK6uxcmpY",
  authDomain: "nosso-chocolate.firebaseapp.com",
  projectId: "nosso-chocolate",
  storageBucket: "nosso-chocolate.appspot.com",
  messagingSenderId: "1006663700417",
  appId: "1:1006663700417:web:d4105507e712b0553ee940"
});
  
  const db = firebase.firestore();
  const auth = firebase.auth();
  const storage = firebase.storage();
  const functions = firebase.functions();
  const provider = new firebase.auth.GoogleAuthProvider();
  
  export {db, auth, storage, functions, provider};