import Header from "../layout/Header";
import { Link, useParams } from "react-router-dom";
import CardCombo from "./components/CardCombo";
import ListaCombo from "./components/ListaCombo";
import { useState, useEffect } from "react";
import firebase from "firebase";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button02 from "./components/Button02";
import Credito from "./components/Credito";

const CombosPromo = ({ uid }) => {
  const db = firebase.firestore();
  const [produtos, setProdutos] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    db.collection("produtos")
      .orderBy("timestamp")
      .onSnapshot(function (snapshot) {
        setProdutos(
          snapshot.docs.map(function (document) {
            return { id: document.id, data: document.data() };
          })
        );
      });
  }, []);

  const avaliableProducts = produtos.filter(
    (produto) => produto.data.disponibilidade === true
  );

  const filteredProducts = avaliableProducts.filter(
    (produto) => produto.data.slug === id
  );

  const openCombo = (par) => {
    document.getElementById(par).style.width = "100%";
  };
  const closeModal = (par) => {
    document.getElementById(par).style.width = "0%";
  };
  const selectCombo = (e, par) => {
    e.preventDefault();
    alert(par);
    document.getElementById("modal_pizzas").style.width = "0%";
  };

  return (
    <div className="clear_background">
      <ToastContainer theme="dark" position="top-center" />
      <div className="clear_background_container">
        <Header uid={uid} />

        <div className="container_comum">
          <div className="combos_promo">
            <div className="tittle_01">
              <Link to="/pedido">
                <i className="bi bi-arrow-left-circle-fill"></i>
              </Link>
              <h4>
                <i className="bi bi-bag-heart"></i>Combos e Promoções
              </h4>
              <div></div>
            </div>
            {filteredProducts.map((val) => {
              return (
                <div key={val.id}>
                  <div className="modal_combo" id={"combo" + val.id}>
                    <ListaCombo
                      closeModal={closeModal}
                      comboId={"combo" + val.id}
                      nome={val.data.nomeProduto}
                      info={val.data.descProduto}
                      qtdMin={val.data.qtdMin}
                      img={val.data.image}
                      valor={val.data.valorProduto}
                      db={db}
                      uid={uid}
                    />
                  </div>
                  <CardCombo
                    nome={val.data.nomeProduto}
                    info={val.data.descProduto}
                    img={val.data.image}
                    action={() => openCombo("combo" + val.id)}
                    valor={val.data.valorProduto}
                    qtdMin={val.data.qtdMin}
                  />
                </div>
              );
            })}
            <Button02 value="Finalizar o Pedido" to="/finalizar-pedido" />
            <Button02 value="Continuar Comprando" to="/pedido" />
            <Credito />
          </div>
        </div>
      </div>
    </div>
  );
};
export default CombosPromo;
