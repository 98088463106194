import enviado from './../../img/sent_purple.png'
import recebido from './../../img/received_purple.png'
import delivery from './../../img/delivery_purple.png'
import preparando from './../../img/coocking_purple.png'

const AcompanharBottom =({status})=>{
    return(
        <div className="acompanhar_info">
            <div className="status_bottom_check"><i className="bi bi-check-circle-fill"></i><img src={enviado} alt="" /></div>
           {status === 'recebido' || status === 'preparando' || status === 'saiu para entrega' ?
           (<div className="status_bottom_check"><i className="bi bi-check-circle-fill"></i><img src={recebido} alt="" /></div>)
           :
           (<div className="status_bottom_waiting"><img src={recebido} alt="" /></div>)}
           
           {status === 'preparando' || status === 'saiu para entrega' ?
           (<div className="status_bottom_check"><i className="bi bi-check-circle-fill"></i><img src={preparando} alt="" /></div>)
           :
           (<div className="status_bottom_waiting"><img src={preparando} alt="" /></div>)}
           
           {status === 'saiu para entrega' ?
           (<div className="status_bottom_check"><i className="bi bi-check-circle-fill"></i><img src={delivery} alt="" /></div>)
           :
           (<div className="status_bottom_waiting"><img src={delivery} alt="" /></div>)}
        </div>
        )
}
export default AcompanharBottom