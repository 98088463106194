import firebase from "firebase";
import cep from "cep-promise";
import { useState, useEffect } from "react";
import InputMask from "react-input-mask";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CadastroAdd = ({ email, uid }) => {
  const [zip, setZip] = useState();
  const db = firebase.firestore();
  const [kart, setKart] = useState([]);
  const navigate = useNavigate();
  const [btn_state, setBtn_state] = useState(false);

  useEffect(() => {
    db.collection("users")
      .doc(uid)
      .collection("carrinho")
      .onSnapshot(function (snapshot) {
        setKart(
          snapshot.docs.map(function (document) {
            return { id: document.id, data: document.data() };
          })
        );
      });
  }, []);

  const determinarCEP = (e) => {
    e.preventDefault();
    let zipcode = document.getElementById("zipcode").value;
    setZip(zipcode);
  };

  useEffect(() => {
    if (zip != null) {
      cep(zip)
        .then((val) => {
          document.getElementById("street").value = val.street;
          document.getElementById("neighborhood").value =
            val.neighborhood + " - " + val.city + "/" + val.state;
        })
        .catch(() => {
          document.getElementById("street").value = "inexistente";
          document.getElementById("neighborhood").value = "inexistente";
        });
    }
  }, [zip]);

  const cadastroCliente = (e) => {
    e.preventDefault();
    let name = document.getElementById("name").value;
    let zipcode = document.getElementById("zipcode").value;
    let street = document.getElementById("street").value;
    let number = document.getElementById("number").value;
    let complement = document.getElementById("complement").value;
    let phone = document.getElementById("phone").value;
    let neighborhood = document.getElementById("neighborhood").value;

    db.collection("users")
      .doc(uid)
      .collection("cadastro")
      .add({
        name: name,
        email: email,
        zipcode: zipcode,
        street: street,
        number: number,
        complement: complement,
        neighborhood: neighborhood,
        phone: phone,
        typeOfCad: "cliente",
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      })
      .then(() => {
        //voltar para a home
        toast.success("Cadastrando seus dados...");
        document.getElementById("submit").disabled = true;
        setTimeout(redirect, 4000);
        db.collection("users").doc(uid).update({
          email: email,
        });
      });
    db.collection("users").doc(uid).set({
      name: name,
    });
  };

  const redirect = () => {
    {
      kart.length > 0 ? navigate("/finalizar-pedido") : navigate("/");
    }
  };

  return (
    <div className="form_cadastro">
      <ToastContainer theme="dark" position="top-center" />
      <form onSubmit={(e) => cadastroCliente(e)}>
        <input
          type="text"
          name="name"
          id="name"
          placeholder="Nome..."
          required
        />
        <input
          type="text"
          name="email"
          id="email"
          placeholder="email"
          value={email}
          readOnly
        />
        <InputMask
          mask="99999-999"
          name="zipcode"
          id="zipcode"
          placeholder="CEP..."
          type="text"
          onBlur={determinarCEP}
          required
        />
        <input
          type="text"
          name="street"
          id="street"
          placeholder="Rua/Av"
          required
        />
        <input
          type="number"
          name="number"
          id="number"
          placeholder="N°..."
          style={{ width: "29%", marginRight: "2%" }}
          required
        />
        <input
          type="text"
          name="complement"
          id="complement"
          placeholder="complemento"
          style={{ width: "69%" }}
        />
        <input
          type="text"
          name="neighborhood"
          id="neighborhood"
          placeholder="Bairro..."
          required
        />
        <InputMask
          mask="(99) 9.9999-9999"
          type="phonenumber"
          name="phone"
          id="phone"
          placeholder="Telefone..."
          required
        />
        <input
          type="submit"
          name="acao"
          value="Cadastrar"
          btn_state={btn_state}
        />
      </form>
    </div>
  );
};
export default CadastroAdd;
