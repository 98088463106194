import { useState, useEffect } from 'react';
import firebase from "firebase";

const MeusPedidos_Card =({pedidos, pedidoId, total, numeroPedido, statusPedido, data, hora})=>{
    const [produtos, setProdutos] = useState(['Nenhum Produto'])
    const [qtd, setQtd] = useState([0])

    useEffect(()=>{
        if(pedidos.length > 0){
            let pedidoAtual = pedidos.filter(pedidos => pedidos.id === pedidoId)
            setProdutos(pedidoAtual[0].data.produtos)
            setQtd(pedidoAtual[0].data.quantidades)
        }
    }, [pedidos])


    return(<>
    {statusPedido !== 'entregue' & statusPedido !== 'pedido cancelado'
    ?(<div className="card_meuspedidos"  style={{backgroundColor:'#eebb77',color:'#442200'}} >
            <div className="top_meuspedidos"><span><b>N° {numeroPedido}</b></span><span>Data {data}</span></div>
            <div className="top_meuspedidos"><span>total: R${total.toFixed(2)}</span><span>Hora: {hora}</span></div>

            <div className="middle_meuspedidos">
                <div className="container_meuspedidos">
                {qtd.map((val, index)=>{
                    return (
                        <p key={index}>{val} - {produtos[index]}</p>
                    )
                    
                })}
                </div>
            </div>
            <div className="top_meuspedidos"><span>Status do Pedido: {statusPedido}</span></div>
        </div>)
        : statusPedido === 'pedido cancelado' ?(
            <div className="card_meuspedidos"  style={{backgroundColor:'#990000'}} >
            <div className="top_meuspedidos"><span><b>N° {numeroPedido}</b></span><span>Data {data}</span></div>
            <div className="top_meuspedidos"><span>total: R${total.toFixed(2)}</span><span>Hora: {hora}</span></div>

            <div className="middle_meuspedidos">
                <div className="container_meuspedidos">
                {qtd.map((val, index)=>{
                    return (
                        <p key={index}>{val} - {produtos[index]}</p>
                    )
                    
                })}
                </div>
            </div>
            <div className="top_meuspedidos"><span>Status do Pedido: {statusPedido}</span></div>
        </div>
        )
        :(<div className="card_meuspedidos">
                <div className="top_meuspedidos"><span><b>N° {numeroPedido}</b></span><span>Data {data}</span></div>
                <div className="top_meuspedidos"><span>total: R${total.toFixed(2)}</span><span>Hora: {hora}</span></div>

                <div className="middle_meuspedidos">
                    <div className="container_meuspedidos">
                    {qtd.map((val, index)=>{
                        return (
                            <p key={index}>{val} - {produtos[index]}</p>
                        )
                        
                    })}
                    </div>
                </div>
                <div className="top_meuspedidos"><span>Status do Pedido: {statusPedido}</span></div>
            </div>)}
    </>)
}
export default MeusPedidos_Card