import styles from './cssForLogon/Logon.module.css'
import LogoGoogle from './cssForLogon/google_logo.png'
import Logo_inicio from './../img/logo_vertical_dark.png'
import OpenedPlace from './../img/oppened.png'
import { Link, useNavigate } from 'react-router-dom'
import { auth } from './../firebase'

const Recover =({loginGoogle})=>{

    const navigate = useNavigate();
    
    function sendPasswordReset(e) {
        e.preventDefault()

        let email1 = document.getElementById('email-req').value;
        let email2 = document.getElementById('email-conf').value;

        {email1 === email2 ?
        (
            
        // [START auth_send_password_reset]
        auth.sendPasswordResetEmail(email1)
          .then(() => {
            // Password reset email sent!
            alert('O e-mail foi enviado para '+email1)
            
          }).then(()=>{
            navigate('/')
          })
          .catch((error) => {
            var errorCode = error.code;
            var errorMessage = error.message;
            alert(errorMessage)
          })
            // [END auth_send_password_reset]
        )
        :
        (alert('Porfavor, digite o e-mail corretamente'))}
      }

    return(
        <div className={styles.container_logon}>
            <div className={styles.container_form_panda}>
                <div className={styles.logo_logon}>
                    <img alt="" src={Logo_inicio} />
                </div>
                <div className={styles.opened_place}>
                    <img alt="" src={OpenedPlace} />
                </div>
                <div className={styles.form_panda}>
                <form onSubmit={sendPasswordReset}>
                <input
                    id="email-req"
                    name="email"
                    placeholder="Digite seu e-mail..."
                    type="email"
                />
                <input
                    id="email-conf"
                    name="email"
                    placeholder="Repita seu e-mail..."
                    type="email"
                />
                <p>Um e-mail será enviado para você. Siga as instruções. Caso não tenha recebido, verifique sua caixa de spam.</p>
                <input type="submit" name="action" value="Recuperar senha"/>
                </form>
                </div>
                <div className={styles.no_login}>
                  <Link to="/register"><p>Sou novo aqui, quero me <span style={{textDecoration:'none'}}>cadastrar!</span></p></Link>
                  <Link to="/"><p>Voltar à tela de login</p></Link>
                </div>
                <div className={styles.login_google}>
                    <button onClick={loginGoogle}><img alt="" src={LogoGoogle} /><span>Entrar com Google</span></button>
                </div>
            </div>
        </div>
        )
}

export default Recover