import firebase from "firebase";
import { useState, useEffect } from 'react';
import Header from "../layout/Header"
import {Link, useNavigate} from 'react-router-dom'
import CartContainer from "./components/CartContainer";

const Carrinho = ({uid})=>{

    const db = firebase.firestore()
    const [cadastro, setCadastro] = useState([]);
    const navigate = useNavigate();

    useEffect(()=>{
        db.collection('users').doc(uid).collection('cadastro').onSnapshot(function(snapshot){
            setCadastro(snapshot.docs.map(function(document){
                return{id:document.id,data:document.data()}
              }))
            })
        },[])

    const directToCadastro = ()=>{
        navigate('/cadastro');
    }
    
    return(cadastro.length > 0 ?
        (
            <div className="clear_background">
            <div className="clear_background_container">
                <Header uid={uid}/>
                <div className="container_comum">
                    <div className="finalizar_pedido">
                    <div className="tittle_01">
                        <Link to="/pedido"><i className="bi bi-arrow-left-circle-fill"></i></Link>
                        <h4><i className="bi bi-bag-heart"></i> Finalizar Pedido</h4>
                        <div style={{marginLeft:'40px'}}></div>
                    </div>
                    <div>
                        <CartContainer uid={uid} cadastro={cadastro} />
                    </div>
                </div>
            </div>
        </div>
        </div>
        ):
        (
            <>
            {directToCadastro()}
            </>
        )
        )
    }

export default Carrinho