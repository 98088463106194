import firebase from "firebase";
import cep from "cep-promise";
import { useState, useEffect } from "react";
import InputMask from "react-input-mask";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CadastroEdit = ({ dados, uid }) => {
  const [zip, setZip] = useState(dados.zipcode);
  const db = firebase.firestore();
  const navigate = useNavigate();
  const [btn_state, setBtn_state] = useState(false);

  const determinarCEP = (e) => {
    e.preventDefault();
    let zipcode = document.getElementById("zipcode").value;
    setZip(zipcode);
  };

  useEffect(() => {
    cep(zip).then((val) => {
      document.getElementById("street").value = val.street;
      document.getElementById("neighborhood").value =
        val.neighborhood + " - " + val.city + "/" + val.state;
    });
  }, [zip]);

  const cadastroCliente = (e) => {
    e.preventDefault();
    let name = document.getElementById("name").value;
    let zipcode = document.getElementById("zipcode").value;
    let street = document.getElementById("street").value;
    let number = document.getElementById("number").value;
    let complement = document.getElementById("complement").value;
    let phone = document.getElementById("phone").value;
    let neighborhood = document.getElementById("neighborhood").value;

    db.collection("users")
      .doc(uid)
      .collection("cadastro")
      .doc(dados[0].id)
      .update({
        name: name,
        zipcode: zipcode,
        street: street,
        number: number,
        complement: complement,
        neighborhood: neighborhood,
        phone: phone,
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      })
      .then(() => {
        toast.success("Atualizando seus dados...");
        let submit = document.getElementById("submit");
        let ableSubmit = () => {
          submit.disabled = false;
        };
        submit.disabled = true;
        setTimeout(ableSubmit, 7000);
      });
  };

  return (
    <div className="form_cadastro">
      <ToastContainer theme="dark" position="top-center" />
      {dados.map((val) => {
        return (
          <form onSubmit={(e) => cadastroCliente(e)} key={val.id}>
            <input
              type="text"
              name="name"
              id="name"
              defaultValue={val.data.name}
            />
            <input
              type="text"
              name="email"
              id="email"
              placeholder="email"
              value={val.data.email}
              readOnly
            />
            <InputMask
              mask="99999-999"
              name="zipcode"
              id="zipcode"
              placeholder="CEP..."
              type="text"
              onBlur={determinarCEP}
              defaultValue={val.data.zipcode}
            />
            <input
              type="text"
              name="street"
              id="street"
              defaultValue={val.data.street}
            />
            <input
              type="number"
              name="number"
              id="number"
              defaultValue={val.data.number}
              style={{ width: "29%", marginRight: "2%" }}
            />
            <input
              type="text"
              name="complement"
              id="complement"
              defaultValue={val.data.complement}
              style={{ width: "69%" }}
            />
            <input
              type="text"
              name="neighborhood"
              id="neighborhood"
              placeholder="Bairro..."
              defaultValue={val.data.neighborhood}
            />
            <InputMask
              mask="(99) 9.9999-9999"
              type="phonenumber"
              name="phone"
              id="phone"
              placeholder="Telefone..."
              defaultValue={val.data.phone}
            />
            <input type="submit" id="submit" name="acao" value="Atualizar" />
          </form>
        );
      })}
    </div>
  );
};

export default CadastroEdit;
