import LogoHeader from './../img/logo_horizontal_dark.png'
import { Link, useNavigate } from "react-router-dom";
import HomeValue from '../pages/components/HomeValue';

const Header = ({uid})=>{
    
    const navigate = useNavigate();

    const gotoCart = ()=>{
        navigate('/finalizar-pedido'); 
    }

    return(
        <header>
            <Link to="/pedido"><img alt="" src={LogoHeader} /></Link>
            <div className="btn-top-cart" onClick={gotoCart}>
                <div><i className="bi bi-bag-heart"></i></div>
                <div><h5>R$ <HomeValue uid={uid}/></h5><p>Total do pedido</p></div>
            </div>
        </header>
    )
}
export default Header