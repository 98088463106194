import "./App.css";
import PandaRouter from "./routes/PandaRouter";
import Loader from "./pages/components/Loader";
import { useState } from "react";
import "bootstrap-icons/font/bootstrap-icons.css";

function App() {
  const [removeLoading, setRemoveLoading] = useState(false);

  window.onload = () => {
    setRemoveLoading(true);
  };

  const loaderDesapear = () => {
    setRemoveLoading(true);
  };

  setTimeout(loaderDesapear, 2500);

  return (
    <div className="App">
      <PandaRouter />
      {!removeLoading && <Loader />}
    </div>
  );
}

export default App;
