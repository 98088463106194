import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import moment from "moment";
import EscolhasFinais from "./EscolhasFinais";
import LocalEntrega from "./LocalEntrega";
import { storage } from "./../../firebase";
import firebase from "firebase";
import { v4 as uuidv4 } from "uuid";
import Button02 from "./Button02";
import Credito from "./Credito";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CartContainer = ({ uid, cadastro }) => {
  const navigate = useNavigate();

  const [date, setDate] = useState(new Date());
  var reqnumb = moment(date).format("MMDDHHssSSS");
  var dia = moment(date).format("DD");
  var mes = moment(date).format("MM");
  var ano = moment(date).format("YYYY");

  const db = firebase.firestore();
  const [kart, setKart] = useState([]);
  const [taxa, setTaxa] = useState();
  const [entrega, setEntrega] = useState("");
  const [pagamento, setPagamento] = useState("");
  const [comprovantepix, setComprovantepix] = useState("nunhum comprovante");
  const [file, setFile] = useState(null);

  //DADOS
  useEffect(() => {
    db.collection("users")
      .doc(uid)
      .collection("carrinho")
      .onSnapshot(function (snapshot) {
        setKart(
          snapshot.docs.map(function (document) {
            return { id: document.id, data: document.data() };
          })
        );
      });
  }, []);

  //FUNÇÕES
  const enviarComprovante = (e) => {
    e.preventDefault();
    var storageRef = firebase.storage().ref();
    let nameRenamed = uuidv4() + file.name;

    const uploadTask = storageRef
      .child("images_comprovantespix/" + nameRenamed)
      .put(file);

    uploadTask.on(
      "state_changed",
      function (snapshot) {
        document.getElementById("btn_enviar").disabled = true;
      },
      function (error) {
        alert(error.message);
      },
      function () {
        storage
          .ref("images_comprovantespix")
          .child(nameRenamed)
          .getDownloadURL()
          .then(function (url) {
            setComprovantepix(url);
            setFile(null);

            document.getElementById("file").value = "";
            document.getElementById("btn_enviar").disabled = false;
          });
      }
    );
  };
  useEffect(() => {
    entrega === "delivery" ? setTaxa(5) : setTaxa(0);
  }, [entrega]);
  const sumall = kart
    .map(({ data }) => data.valor)
    .reduce((prev, curr) => prev + curr, 0);
  const total = sumall + taxa;
  const redirect = () => {
    navigate("/acompanhar");
  };
  const enviarPedido = (e) => {
    e.preventDefault();

    let produtos = kart.map(({ data }) => data.produto);
    let data = moment(date).format("DD/MM/YYYY");
    let hora = moment(date).format("HH:mm");
    let quantidades = kart.map(({ data }) => data.quantidade);
    let street = cadastro[0].data.street;
    let email = cadastro[0].data.email;
    let number = cadastro[0].data.number;
    let complement = cadastro[0].data.complement;
    let name = cadastro[0].data.name;
    let phone = cadastro[0].data.phone;
    let address = "Rua/Av: " + street + ",n°: " + number + " - " + complement;
    let client = "Cliente: " + name + " - Tel: " + phone;
    let troco =
      pagamento === "dinheiro"
        ? document.getElementById("troco").value
        : "Foi escolhida outra forma de pagamento";

    db.collection("pedidos")
      .add({
        idCliente: uid,
        dataPedido: data,
        horaPedido: hora,
        emailcliente: email,
        quantidades: quantidades,
        produtos: produtos,
        endereço: address,
        cliente: client,
        total: total,
        numeroPedido: reqnumb,
        statusPedido: "enviado",
        trocoPara: troco,
        formaEntrega: entrega,
        formaPagamento: pagamento,
        comprovantepix: comprovantepix,
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        dia: parseFloat(dia),
        mes: parseFloat(mes),
        ano: parseFloat(ano),
      })
      .then(() => {
        let path = db.collection("users").doc(uid).collection("carrinho");
        let kartToDelete = kart.map(({ id }) => path.doc(id).delete());
        //console.log(kartToDelete)
        toast.success("O pedido está sendo enviado...");
        setTimeout(redirect, 6000);
      });
  };
  const deletarProduto = (e, par) => {
    e.preventDefault();
    db.collection("users").doc(uid).collection("carrinho").doc(par).delete();
  };
  const definirEntrega = () => {
    let tipoEntrega = document.getElementById("entrega").value;
    setEntrega(tipoEntrega);
  };
  const definirPagamento = () => {
    let tipoPagamento = document.getElementById("pagamento").value;
    setPagamento(tipoPagamento);
  };

  return (
    <div>
      <ToastContainer theme="dark" position="top-center" />
      {kart.length > 0 ? (
        <>
          <div className="pedido_container">
            <table className="pedido_carrinho">
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }}>Qtd</th>
                  <th>Produto</th>
                  <th>Valor</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {kart.map((val) => {
                  return (
                    <tr key={val.id}>
                      <td style={{ textAlign: "center" }}>
                        {val.data.quantidade}
                      </td>
                      <td>{val.data.produto}</td>
                      <td>R${val.data.valor.toFixed(2)}</td>
                      <td style={{ paddingRight: "18px" }}>
                        <i
                          className="bi bi-x-circle-fill"
                          onClick={(e) => deletarProduto(e, val.id)}
                        ></i>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <EscolhasFinais
            definirEntrega={definirEntrega}
            definirPagamento={definirPagamento}
            pagamento={pagamento}
            entrega={entrega}
            enviarComprovante={enviarComprovante}
            setFile={setFile}
          />
          {entrega === "balcao" ||
          (entrega === "delivery") & (pagamento !== "") ? (
            <>
              <div className="pedido_total">
                <span>Taxa de entrega: </span>
                <span>R${taxa},00</span>
              </div>
              <div className="pedido_total">
                <span>Total: </span>
                <span>R${total.toFixed(2)}</span>
              </div>
              <LocalEntrega
                cadastro={cadastro}
                pagamento={pagamento}
                entrega={entrega}
              />
              <Button02 value="Enviar o Pedido" action={enviarPedido} />
              <Link to="/pedido">
                <Button02 value="Continuar comprando" to="/pedido" />
              </Link>
              <Credito />
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <div>
          <h4 style={{ marginTop: "150px" }}>O carrinho está vazio!</h4>
          <Button02 value="Continuar comprando" to="/pedido" />
        </div>
      )}
    </div>
  );
};
export default CartContainer;
