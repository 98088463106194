import Header from "../layout/Header";
import { Link } from "react-router-dom";
import Credito from "./components/Credito";

const Info = ({ uid }) => {
  return (
    <div className="clear_background">
      <div className="clear_background_container">
        <Header uid={uid} />
        <div className="container_comum">
          <div className="container_acompanhar">
            <div className="tittle_01">
              <Link to="/">
                <i className="bi bi-arrow-left-circle-fill"></i>
              </Link>
              <h5 style={{ color: "#442200" }}>
                <i className="bi bi-info-circle"></i> Informações
              </h5>
              <div style={{ marginLeft: "30px" }}></div>
            </div>
            <div className="conteudo_info">
              <div className="image_info"></div>
              <p>
                Seja para um casamento, uma festa de aniversário tematizada, um
                batizado ou mesmo um evento empresarial, temos o sabor certo que
                atende ao mais exigentes paladares. Chocolates artesanais e de
                bom gosto.
              </p>
              <div className="image_info"></div>
              <p>
                A confeitaria NOSSO CHOCOLATE foi fundada em 2010, pela Chef
                confeiteira Regiane Lorem Ipsum is simply dummy text of the
                printing and typesetting industry. Lorem Ipsum has been the
                industry's standard dummy text ever since the 1500s, when an
                unknown printer took a galley of type and scrambled it to make a
                type specimen book. It has survived not only five centuries, but
                also the leap into electronic typesetting, remaining essentially
                unchanged. It was popularised in the 1960s with the release of
                Letraset sheets containing Lorem Ipsum passages, and more
                recently with desktop publishing software like Aldus PageMaker
                including versions of Lorem Ipsum.
              </p>
            </div>
            <Credito />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Info;
