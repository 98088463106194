const LocalEntrega = ({cadastro, entrega})=>{

    return(
        <>
        <div className="pedido_local">
            <span>Local da entrega:<br/></span>
                {entrega === 'delivery'
                ?(cadastro.map((doc)=>{
                        return(
                            <div key={doc.id}>
                                <span>Rua: {doc.data.street}, n°{doc.data.number} - {doc.data.complement}</span><br/>
                                <span>Cliente: {doc.data.name} - Telefone: {doc.data.phone}</span>
                                
                            </div>
                        )
                })
                )
            :entrega === 'balcao' ? (
                    <div>
                        <span>Balcão de nosso estabelecimento</span><br/>
                        <span>Rua: Apinagés, 84 - Perdizes</span>
                    </div>
            ):(<></>)}
                
        </div>
        </>
        )
}

export default LocalEntrega