import { useEffect,useState } from 'react';
import firebase from "firebase";
import Header from "../layout/Header"
import {Link} from 'react-router-dom'
import CadastroEdit from './CadastroEdit'
import CadastroAdd from './CadastroAdd';

const Cadastro =({uid, email})=>{

    const db = firebase.firestore();
	const [dados, setDados] = useState([]);

    useEffect(()=>{
        db.collection('users').doc(uid).collection('cadastro').onSnapshot(function(snapshot){
            setDados(snapshot.docs.map(function(document){
                return{id:document.id,data:document.data()}
            }))
          })
    },[])

    return(
        <div>
            <div className="clear_background">
            <div className="clear_background_container">
                <Header uid={uid}/>
                <div className="container_comum">
                    <div className="container_cadastro">
                        <div className="tittle_01">
                            <Link to="/pedido"><i className="bi bi-arrow-left-circle-fill"></i></Link>
                            <h5 style={{color:'#442200'}}><i className="bi bi-person-circle"></i> Cadastro</h5>
                            <div style={{marginLeft:'30px'}}></div>
                        </div>
                        {dados.length > 0
                        ?(<CadastroEdit dados={dados} uid={uid} />)
                        :(<CadastroAdd uid={uid} email={email} />)}
                        
                    </div>
                </div>
            </div>
            </div>
        </div>
        )
}
export default Cadastro