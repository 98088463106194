const Loader = () => {
  return (
    <div className="bgloader" id="loader">
      <div className="img_container_loader"></div>
      <div className="lds_hourglass"></div>
      <div className="bg_overlay"></div>
    </div>
  );
};
export default Loader;
