const EscolhasFinais = ({definirEntrega, definirPagamento, entrega, pagamento, enviarComprovante, setFile})=>{

//para copiar um código de pagamento, posso usar o comando abaixo:
//navigator.clipboard.writeText('aqui vai o código do PIX');

    return (
        <div className='escolhas_finais'>
        <div className="selecttipo">
            <label htmlFor='entrega'>Entrega / Retirada</label>
            <select name="entrega" form="entrega" id="entrega" required onChange={definirEntrega}>
                <option disabled selected>Escolha forma de retirada...</option>
                <option value="delivery">Entrega no seu endereço</option>
                <option value="balcao">Retirada no balcão</option>
            </select>
        </div>
        {entrega === "delivery"
        ?(<>
          <div className="selecttipo">
                <label htmlFor='entrega'>Forma de pagamento</label>
                <select name="pagamento" form="pagamento" id="pagamento" required onChange={definirPagamento}>
                    <option disabled selected>Escolha forma de pagamento...</option>
                    <option value="debito">Cartão de débito</option>
                    <option value="credito">Cartão de crédito</option>
                    <option value="dinheiro">Dinheiro</option>
                    <option value="pix">PIX</option>
                </select>
          </div>
          {pagamento === 'dinheiro'
            ?(
                <div className='selecttipo'>
                    <label htmlFor='troco'>Troco para:</label>
                    <input type="text" id='troco' name='troco' placeholder='R$...' />
                </div>
            )
            : pagamento === 'pix' ?
            (
                <div className='selecttipo'>
                    <label htmlFor='troco'>Ao fazer o PIX clique em enviar comprovante:</label>
                    Pagamento PIX IMPORTAR
                </div>
            )
            :(<></>)}
          </>
        )
        :(<></>)}
        </div>
        
    )
}

export default EscolhasFinais