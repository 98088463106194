import { Link } from "react-router-dom";

const CardPanda = (props) => {
  return (
    <Link to={props.direction}>
      <div
        className={`card_pedido`}
        style={{ backgroundImage: `url(${props.image})` }}
      >
        <div className="over_card">
          <h4>{props.produto}</h4>
          <p>
            {props.linha01}
            <br />
            {props.linha02}
          </p>
        </div>
      </div>
    </Link>
  );
};

export default CardPanda;
