import Header from "../layout/Header";
import Button02 from "./components/Button02";
import CardPanda from "./components/CardPanda";
import { useState, useEffect } from "react";
import firebase from "firebase";

const Pedido = ({ uid }) => {
  const db = firebase.firestore();
  const [categorias, setCategorias] = useState([]);
  useEffect(() => {
    db.collection("categorias")
      .orderBy("timestamp")
      .onSnapshot(function (snapshot) {
        setCategorias(
          snapshot.docs.map(function (document) {
            return { id: document.id, data: document.data() };
          })
        );
      });
  }, []);

  const categoriasDisponiveis = categorias.filter(
    (categoria) => categoria.data.disponibilidade === true
  );

  return (
    <div className="clear_background">
      <div className="clear_background_container">
        <Header uid={uid} />
        <div className="container_comum">
          <div className="title">
            <h3>O que vai ser hoje?</h3>
          </div>
          <div className="container_pedidos">
            {categoriasDisponiveis.map((val) => {
              return (
                <CardPanda
                  key={val.id}
                  produto={val.data.nomeCategoria}
                  linha01={val.data.linha01}
                  linha02={val.data.linha02}
                  url={val.data.url}
                  direction={"/pedido/" + val.data.slug}
                  image={val.data.image}
                />
              );
            })}

            <Button02
              value="Finalizar o Pedido"
              to="/finalizar-pedido"
              btn_state={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pedido;
