import { auth, provider } from "./../firebase.js";
import firebase from "firebase";
import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
  HashRouter,
} from "react-router-dom";
import Home from "./../pages/Home";
import Logon from "./../pages/Logon";
import Register from "./../pages/Register";
import Recover from "./../pages/Recover";
import Footer from "../layout/Footer";
import Acompanhar from "../pages/Acompanhar";
import Pedido from "../pages/Pedido";
import CombosPromo from "./../pages/CombosPromo";
import Carrinho from "../pages/Carrinho.js";
import Cadastro from "../pages/Cadastro.js";
import MeusPedidos from "../pages/MeusPedidos.js";
import Info from "../pages/Info";
import AbrirFechar from "../layout/AbrirFechar.js";

const PandaRouter = () => {
  const db = firebase.firestore();
  const [dados, setDados] = useState([]);
  const [login, setLogin] = useState(null);

  useEffect(() => {
    auth.onAuthStateChanged((val) => {
      if (val != null) {
        setLogin({
          email: val.email,
          uid: val.uid,
        });
      }
    });

    db.collection("usuarios").onSnapshot(function (snapshot) {
      setDados(
        snapshot.docs.map(function (document) {
          return { id: document.id, data: document.data() };
        })
      );
    });
  }, []);

  function logar(e) {
    e.preventDefault();
    //parametros
    let email = document.getElementById("email-login").value;
    let senha = document.getElementById("senha-login").value;

    auth
      .signInWithEmailAndPassword(email, senha)
      .then((auth) => {
        setLogin(auth.user.email);
        window.location.href = "/";
      })
      .catch((err) => {
        alert(err.message);
      });
  }

  function deslogar(e) {
    e.preventDefault();
    auth.signOut().then(function () {
      window.location.href = "/";
    });
  }

  const loginGoogle = (e) => {
    e.preventDefault();
    alert("logando com Google");
    auth.signInWithPopup(provider).then((result) => {
      if (result) {
        setLogin(result.user.email);
      }
    });
  };

  return (
    <div>
      <AbrirFechar />
      {login ? (
        <div>
          <HashRouter>
            <Routes>
              <Route
                exact
                path="/"
                element={<Home deslogar={deslogar} uid={login.uid} />}
              />
              <Route
                exact
                path="/acompanhar"
                element={<Acompanhar uid={login.uid} />}
              />
              <Route
                exact
                path="/meus-pedidos"
                element={<MeusPedidos uid={login.uid} />}
              />
              <Route exact path="/info" element={<Info uid={login.uid} />} />
              <Route
                exact
                path="/pedido"
                element={<Pedido uid={login.uid} />}
              />
              <Route
                exact
                path="/pedido/:id"
                element={<CombosPromo uid={login.uid} />}
              />
              <Route
                exact
                path="/combos-promo"
                element={<CombosPromo uid={login.uid} />}
              />
              <Route
                exact
                path="/finalizar-pedido"
                element={<Carrinho uid={login.uid} />}
              />
              <Route
                exact
                path="/cadastro"
                element={<Cadastro uid={login.uid} email={login.email} />}
              />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
            <Footer deslogar={deslogar} />
          </HashRouter>
        </div>
      ) : (
        <HashRouter>
          <Routes>
            <Route
              exact
              path="/"
              element={<Logon logar={logar} loginGoogle={loginGoogle} />}
            />
            <Route
              exact
              path="/register"
              element={<Register logar={logar} loginGoogle={loginGoogle} />}
            />
            <Route
              exact
              path="/recover"
              element={<Recover logar={logar} loginGoogle={loginGoogle} />}
            />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </HashRouter>
      )}
    </div>
  );
};

export default PandaRouter;
