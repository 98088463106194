import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Legend);

const Progress = ({and})=>{

	//console.log(and)

	if(and === 'enviado'){
		var and = 20;
	}else if(and === 'recebido'){
		var and = 40;
	}else if(and === 'preparando'){
		var and = 60;
	}else{
		var and = 80;
	}

	var andamento = and;
	var andgraf = (andamento*360/100);
	var negativo = (100 - andamento)

	const data = {
		labels: [],
		datasets: [
		  {
			label: '# of Votes',
			data: [andamento, negativo],
			backgroundColor: [
				'rgba(222,184,135,1.00)',
				'rgba(54, 162, 235, 0)',
			],
			borderColor: [
				'rgba(222,184,135,1.00)',
				'rgba(54, 162, 235, 0)',
			],
			borderWidth: 1,
			borderWidth: 1,
			circumference: 360,
			cutout: 95
		  },
		],
	  };

    return(
        
		<Doughnut data={data} />
		
        )
}

export default Progress