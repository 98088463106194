import LogoLeo from './../../img/Logo_leoPalma.png'

const Credito = ()=>{
    return (
        <div className="info_leo">
            <p>App desenvolvido por</p>
            <img alt="" src={LogoLeo} />
        </div>
    )
}

export default Credito