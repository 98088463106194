import Button03 from "./Button03";
import { useState, useEffect } from "react";
import firebase from "firebase";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const ListaCombo = (props) => {
  const [qtd, setQtd] = useState(props.qtdMin);
  const [btn_state, setBtn_state] = useState(false);
  const navigate = useNavigate();

  const db = props.db;
  const uid = props.uid;

  const redirect = () => {
    navigate("/pedido");
  };

  const addQtd = (e, par) => {
    e.preventDefault();
    setQtd(parseInt(qtd) + par);
  };
  useEffect(() => {}, []);

  const includeOnCart = (e, par1) => {
    e.preventDefault();
    let valUnt = props.valor;
    let valorTotal = parseFloat(valUnt * qtd);
    db.collection("users")
      .doc(uid)
      .collection("carrinho")
      .add({
        idUser: uid,
        produto: par1,
        quantidade: qtd,
        valor: valorTotal,
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      })
      .then(() => {
        //voltar para a home
        toast.success("Inserindo este combo ao seu pedido...");
        setTimeout(redirect, 4000);
        setBtn_state(true);
      });
  };

  return (
    <>
      <ToastContainer theme="dark" position="top-center" />
      <div className="lista_combo">
        <div className="tittle_01" style={{ color: "white" }}>
          <div style={{ marginRight: "36px" }}></div>
          <h4>{props.nome}</h4>
          <div onClick={() => props.closeModal(props.comboId)}>
            <i
              className="bi bi-x-circle-fill"
              style={{ color: "white", marginRight: "15px" }}
            ></i>
          </div>
        </div>
        <div className="image_combo">
          <img alt="" src={props.img} />
        </div>
        <div className="texto_combo">
          <p>{props.info}</p>
          <p>
            <b>Valor unitário R${props.valor.toFixed(2)}</b>
            <br />
            Quantidade mínima: <b>{props.qtdMin}</b>
          </p>
        </div>
        <hr />
        <div className="quantidade_combo">
          <span>Quantidade</span>
          <form onSubmit={(e) => includeOnCart(e, props.nome, props.valor)}>
            <button onClick={(e) => addQtd(e, -1)} className="increment">
              <i className="bi bi-dash-circle-fill"></i>
            </button>
            <input
              type="number"
              min={props.qtdMin}
              style={{ width: "80px" }}
              value={qtd}
              onChange={(e) => setQtd(parseInt(e.target.value))}
              required
            />
            <button onClick={(e) => addQtd(e, +1)} className="increment">
              <i className="bi bi-plus-circle-fill"></i>
            </button>
            <div className="final_value">
              <span>
                R$ <b>{(qtd * props.valor).toFixed(2)}</b>
              </span>
            </div>
            <br />
            <Button03
              value="Adicionar ao Pedido"
              nome={props.nome}
              valor={props.valor}
              btn_state={btn_state}
              type="submit"
            />
          </form>
        </div>
      </div>
    </>
  );
};

export default ListaCombo;
