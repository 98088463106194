import PandaSleeping from './../img/panda_sleeping_dark.png'
import { useEffect, useState } from 'react';
import firebase from "firebase";

const AbrirFechar = ()=>{

    const db = firebase.firestore()
    const [status, setStatus] = useState([])
    const [display, setDisplay] = useState('block')
    useEffect(()=>{
        db.collection('openclose').onSnapshot(function(snapshot){
            setStatus(snapshot.docs.map(function(document){
                return{id:document.id,data:document.data()}
            }))
        })
        },[])
    
    if(status.length > 0){
       var statusAtual = status[0].data.status;
    }else{
        var statusAtual = true
    }

    useEffect(()=>{
        if(status.length > 0){
            if(statusAtual == true){
                setDisplay('block')
            }else{
                setDisplay('none')
            }
        }
        //document.getElementById('cover').style.display = display;
    },[status])


    return(
        <>
        {display === 'none'
        ?(<div id="cover" className="abrir_fechar" >
        <img alt="" src={PandaSleeping} />
        <h2>Estamos fechados no momento.</h2>
        <h3>Nosso horário de  funcionamento é:<br/>
            Terça à sábado - 19h às 23h</h3>
        <h3>Aguardamos sua visita!</h3>
        </div>)
        :(<></>)}
        </> 
        )
}

export default AbrirFechar