import styles from "./cssForLogon/Logon.module.css";
import LogoGoogle from "./cssForLogon/google_logo.png";
import Logo_inicio from "./../img/logo_vertical_dark.png";
import OpenedPlace from "./../img/oppened.png";
import { Link } from "react-router-dom";

const Logon = ({ logar, loginGoogle }) => {
  return (
    <div className={styles.container_logon}>
      <div className={styles.container_form_panda}>
        <div className={styles.logo_logon}>
          <img alt="" src={Logo_inicio} />
        </div>
        <div className={styles.form_panda}>
          <form onSubmit={(e) => logar(e)}>
            <input
              id="email-login"
              name="email"
              placeholder="E-mail..."
              type="email"
            />

            <input
              id="senha-login"
              name="password"
              placeholder="Senha..."
              type="password"
            />
            <input type="submit" name="action" value="Entrar" />
          </form>
        </div>
        <div className={styles.no_login}>
          <Link to="/register">
            <p>
              Sou novo aqui, quero me{" "}
              <span style={{ textDecoration: "none" }}>cadastrar!</span>
            </p>
          </Link>
          <Link to="/recover">
            <p>Esqueci minha senha...</p>
          </Link>
        </div>
        <div className={styles.login_google}>
          <button onClick={loginGoogle}>
            <img alt="" src={LogoGoogle} />
            <span>Entrar com Google</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Logon;
