import Header from "../layout/Header"
import {Link} from 'react-router-dom'
import firebase from "firebase";
import { useEffect,useState } from 'react';
import MeusPedidos_Card from "./components/MeusPedidos_Card";

const MeusPedidos =({uid})=>{

    const db = firebase.firestore()
    const [pedidos, setPedidos] = useState([]);

    useEffect(()=>{       
        db.collection('pedidos').where("idCliente","==",uid).onSnapshot(function(snapshot){
            setPedidos(snapshot.docs.map(function(document){
                return{id:document.id,data:document.data()}
              }))
            })
        },[])


    return(
        <div className="clear_background">
            <div className="clear_background_container">
                <Header uid={uid}/>
                <div className="container_comum">
                    <div className="container_acompanhar">
                        <div className="tittle_01">
                            <Link to="/"><i className="bi bi-arrow-left-circle-fill"></i></Link>
                            <h5 style={{color:'#442200'}}><i className="bi bi-bag-check"></i> Meus Pedidos</h5>
                            <div style={{marginLeft:'30px'}}></div>
                        </div>
                        {pedidos.map((val)=>{
                            return(
                                <MeusPedidos_Card
                                    key={val.id}
                                    pedidos={pedidos}
                                    pedidoId={val.id}
                                    numeroPedido={val.data.numeroPedido}
                                    total={val.data.total}
                                    statusPedido={val.data.statusPedido}
                                    timestamp={val.data.timestamp}
                                    data={val.data.dataPedido}
                                    hora={val.data.horaPedido}
                                />
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>    
        )
}
export default MeusPedidos