import { Link } from "react-router-dom";

//onClick={(e)=>props.action(e , props.nome, props.valor)}

const Button03 = (props) => {
  return (
    <div className="btn01">
      <button
        style={{ marginTop: "0px" }}
        disabled={props.btn_state}
        type="submit"
      >
        {props.value}
      </button>
    </div>
  );
};

export default Button03;
