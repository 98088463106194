import LogoHome from './../img/logo_vertical_dark.png'
import Button01 from './components/Button01'

const Home =({uid})=>{
    
    return(
        <div className="container_home">
            <div className='container_home_overlay'>
            <div className="content_home">
                <div className="logo_home">
                    <img alt="" src={LogoHome} />
                </div>
                
                
                <div className="home_nav">
                    <Button01 value='Fazer um pedido' to='/pedido' personstyle='marginBotton:"4px"' />
                    <Button01 value='Cadastro' to='/cadastro'/>
                    <Button01 value='Meus Pedidos' to='/meus-pedidos'/>
                    <Button01 value='Informações' to='/info'/>
                </div>
            </div>
            </div>
        </div>
        )
}

export default Home