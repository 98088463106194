import { useState, useEffect } from 'react';
import firebase from "firebase";

const HomeValue =({uid})=>{
    const db = firebase.firestore()
    const [kart, setKart] = useState([]);
    useEffect(()=>{
        db.collection('users').doc(uid).collection('carrinho').onSnapshot(function(snapshot){
            setKart(snapshot.docs.map(function(document){
                return{id:document.id,data:document.data()}
              }))
            })
        },[])

        const sumall = kart.map(({ data }) => data.valor).reduce((prev, curr) => prev + curr, 0);

    return(
        <>
        {sumall.toFixed(2)}
        </>
        )
}
export default HomeValue